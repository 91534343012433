/** @format */

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { SanityService } from "../sanity-api-service.js";

const redovanServisArr = [
  { name: "9000 Btu", price: "3.500,00" },
  { name: "12000 Btu", price: "3.500,00" },
  { name: "18000 Btu", price: "4.500,00" },
  { name: "24000 Btu", price: "5.500,00" },
];

const velikiServisArr = [
  { name: "9000 Btu", price: "4.500,00" },
  { name: "12000 Btu", price: "5.000,00" },
  { name: "18000 Btu", price: "7.500,00" },
  { name: "24000 Btu", price: "7.500,00" },
];

const montazaArr = [
  { name: "9000 Btu", price: "8.000,00 + 1800/m instalacije" },
  { name: "12000 Btu", price: "10.000,00 + 2000/m instalacije" },
  { name: "18000 Btu", price: "15.000,00 + 3500/m instalacije" },
  { name: "24000 Btu", price: "15.000,00 + 3500/m instalacije" },
  { name: "Demontaža", price: "3.000,00 - 5.000,00" },
];

const popravkeArr = [
  { name: "9000 Btu", price: "7.500,00 - 10.000,00" },
  { name: "12000 Btu", price: "7.500,00 - 10.000,00" },
  { name: "18000 Btu", price: "12.500,00 - 15.000, 00" },
  { name: "24000 Btu", price: "12.500,00 - 15.000, 00" },
];
const doppunaFreonaArr = [
  { name: "Freon R-22", price: "4.500,00" },
  { name: "Freon R-410a", price: "5.500,00" },
  { name: "Freon R32", price: "7.000,00" },
];
const ostaloArr = [
  { name: "Zamena kondenzatora", price: "3.500,00 - 10.000,00" },
  { name: "Zamena step motora", price: "3.500,00" },
  { name: "Zamena turbine", price: "3.500,00" },
  {
    name: "Zamena motora ventilatora spoljne jedinice",
    price: "10.000,00 - 12.000,00",
  },
  { name: "Zamena ventilatora spoljne jedinice", price: "4.000,00" },
  { name: "Zamena holendera", price: "4.000,00 - 6.000,00" },
  { name: "Dolazak/konstatacija kvara", price: "2.000,00" },
];

const BlogDetails = () => {
  const [sanityServices, setSanityServices] = useState(null);
  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = () => {
    SanityService.getServicesPricing()
      .then((sanityObject) => {
        console.log("sanityObject", sanityObject);
        setSanityServices(sanityObject);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <section className="blog_details_section section_padding">
      <Container>
        <Row>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Redovan godišnji servis</h4>
            {sanityServices?.regularAnnualService.map((item, index) => (
              <Row key={index}>
                <Col lg={8} md={6} sm={6} xs={8}>
                  <p>{item.serviceName}</p>
                </Col>
                <Col lg={4} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Veliki servis</h4>
            {sanityServices?.bigService.map((item, index) => (
              <Row key={index}>
                <Col lg={8} md={6} sm={6} xs={8}>
                  <p>{item.serviceName}</p>
                </Col>
                <Col lg={4} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Montaža klima uređaja</h4>
            {sanityServices?.installation.map((item, index) => (
              <Row key={index}>
                <Col lg={4} md={4} sm={6} xs={4}>
                  <p>{item.serviceName}</p>
                </Col>
                <Col lg={8} md={8} sm={6} xs={8} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Popravka elektronike</h4>
            {popravkeArr.map((item, index) => (
              <Row key={index}>
                <Col lg={4} md={6} sm={6} xs={4}>
                  <p>{item.name}</p>
                </Col>
                <Col lg={8} md={6} sm={6} xs={8} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Ostale usluge</h4>
            {ostaloArr.map((item, index) => (
              <Row key={index}>
                <Col lg={8} md={6} sm={6} xs={6}>
                  <p>{item.name}</p>
                </Col>
                <Col lg={4} md={6} sm={6} xs={6} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
          <Col lg={4} style={{ marginBottom: "10px" }}>
            <h4 style={{ color: "#1d458d" }}>Dopuna freona</h4>
            {doppunaFreonaArr.map((item, index) => (
              <Row key={index}>
                <Col lg={8} md={6} sm={6} xs={8}>
                  <p>{item.name}</p>
                </Col>
                <Col lg={4} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                  <p>{item.price}</p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BlogDetails;
